// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/administrator/provider.ts"
);
import.meta.hot.lastModified = "1731679026373.6436";
}
// REMIX HMR END

import {
  AddAdministratorMutation,
  AdministratorListOptions,
  AdministratorQuery,
  AdministratorsQuery,
  CreateAdministratorInput,
  DeleteAdministratorMutation,
  UpdateActiveAdministratorInput,
  UpdateAdministratorInput,
  UpdateAdministratorMutation,
  UpdateActiveAdministratorMutation,
  Rsv_NonAssignedAdministratorsQuery,
  DashboardType,
  SetDashboardMutation,
  ActiveAdministratorDetailsQuery,
} from '~admin/generated/graphql'

import { QueryOptions, sdk } from '~admin/graphqlWrapper'

export const setDashboard = (
  input: DashboardType,
  options?: QueryOptions,
): Promise<SetDashboardMutation> => {
  return sdk.setDashboard({ input }, options)
}

export const availableAdministrators = (
  search: string,
  options?: QueryOptions,
): Promise<Rsv_NonAssignedAdministratorsQuery> => {
  return sdk.rsv_nonAssignedAdministrators({ search }, options)
}

export function getActiveAdministrator(options: QueryOptions) {
  return sdk.activeAdministrator(undefined, options)
}

export function getActiveAdministratorDetails(options: QueryOptions): Promise<ActiveAdministratorDetailsQuery> {
  return sdk.activeAdministratorDetails(undefined, options)
}

export const updateActiveAdministrator = (
  input: UpdateActiveAdministratorInput,
  options?: QueryOptions,
): Promise<UpdateActiveAdministratorMutation> => {
  return sdk.updateActiveAdministrator({ input }, options)
}

export const get = (
  id: string,
  options?: QueryOptions,
): Promise<AdministratorQuery> => {
  return sdk.administrator({ id }, options)
}

export const list = (
  options?: AdministratorListOptions,
  queryOptions?: QueryOptions,
): Promise<AdministratorsQuery> => {
  return sdk.administrators({ options }, queryOptions)
}

export const create = (
  input: CreateAdministratorInput,
  options?: QueryOptions,
): Promise<AddAdministratorMutation> => {
  return sdk.addAdministrator({ input }, options)
}

export const update = (
  input: UpdateAdministratorInput,
  options?: QueryOptions,
): Promise<UpdateAdministratorMutation> => {
  return sdk.updateAdministrator({ input }, options)
}

export const remove = (
  id: string,
  options?: QueryOptions,
): Promise<DeleteAdministratorMutation> => {
  return sdk.deleteAdministrator({ id }, options)
}
